<template xmlns="http://www.w3.org/1999/html">
  <div class="app-container main-content">
    <div class="upload-qtx" v-if="!initiated" style="padding: 50px">
      <center>
        <div style="font-size: 30px; margin-bottom: 80px">
          {{ $t("search.compareColors.uploadQTXToCompare") }}
        </div>
        <el-upload action="#" :on-remove="handleRemove" :file-list="fileList" :before-upload="beforeUpload"
          :on-change="clearFileChange" :http-request="uploadQTXFiles" :show-file-list="false"
          style="display: inline; color: black" drag="">
          <div>
            <i class="el-icon-upload"></i>
            <div class="el-upload__tip_4" slot="tip">
              {{ $t("common.upload.qtx.dragOrClickToUploadQtx")
              }}<em style="color: blueviolet"></em>
            </div>
            <div class="el-upload__tip" slot="tip">
              {{ $t("common.upload.qtx.onlyQTXSupportedMaxSize") }}
            </div>
          </div>
        </el-upload>
      </center>
    </div>

    <div class="compare-content" v-else>
      <el-row>
        <el-col :span="13">
          <div style=" display: inline-block; padding: 10px; border">
            <img src="@/assets/e.dye_logo.png" style="height: 80px" />

            <div class="qtx-file-title" v-if="!compareData.fromSearch">
              <div class="qtx-title" :title="fileList.length > 0 ? fileList[0].name : ''">
                {{ $t("search.compareColors.qtxFile") }}:
                {{ fileList.length > 0 ? fileList[0].name : "" }}
              </div>
              <el-upload action="#" :on-remove="handleRemove" :file-list="fileList" :before-upload="beforeUpload"
                :on-change="clearFileChange" :http-request="uploadQTXFiles" :show-file-list="false"
                style="display: inline; color: black">
                <i class="el-icon-refresh clickable" style="font-size: 20px"
                  :title="$t('search.compareColors.changeQTX')"></i>
              </el-upload>
            </div>
            <div class="qtx-file-title" v-else>
              <i class="el-icon-arrow-left clickable" style="font-size: 14px; margin: 5px; font-weight: bold"
                @click="$router.push({ name: 'search/product' })">
                {{ $t("search.compareColors.backToSearch") }}</i>
              {{ $t("search.compareColors.or") }}
              <el-upload action="#" :on-remove="handleRemove" :file-list="fileList" :before-upload="beforeUpload"
                :on-change="clearFileChange" :http-request="uploadQTXFiles" :show-file-list="false"
                style="display: inline">
                <i class="el-icon-refresh clickable" style="font-size: 14px; margin: 5px; font-weight: bold"
                  :title="$t('search.compareColors.restart')">&nbsp;{{ $t("search.compareColors.restart") }}</i>
                <!-- {{ $t("common.upload.qtx.onlyQTXSupportedMaxSize") }} -->
              </el-upload>
            </div>
          </div>
          <el-row>
            <el-col :span="24" class="comparison-parameters"
              :class="comparisonSettingsVisible ? 'settings-visible' : ''">
              <el-row>
                <el-col :span="15"><i :class="comparisonSettingsVisible
                  ? 'el-icon-arrow-up'
                  : 'el-icon-s-tools'
                  " class="clickable" style="margin-right: 3px; line-height: 1.5" @click="
                    comparisonSettingsVisible = !comparisonSettingsVisible
                    ">&nbsp;{{
                      $t(
                        "search.accurateColorSearch.compareColors.manageTolerances"
                      )
                    }}</i></el-col>
                <el-col :span="9" style="text-align: right; line-height: 1.5">
                  <i :class="comparisonSettingsVisible
                    ? 'el-icon-arrow-up'
                    : 'el-icon-s-tools'
                    " class="clickable" style="margin-right: 3px" @click="
                      comparisonSettingsVisible = !comparisonSettingsVisible
                      ">
                    {{
                      $t("common.color.illuminantShort") +
                      "/" +
                      $t("common.color.observerAngleShort")
                    }}</i>
                  <el-select v-model="currentIllObsName" size="mini" style="width: 120px; margin-left: 5px"
                    @change="illObsChanged">
                    <el-option v-for="item in illObs" :key="item.name"
                      :label="item.illuminant + '/' + item.observerAngle" :value="item.name">
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
              <div v-if="comparisonSettingsVisible" style="padding-top: 3px; margin-top: 3px">
                <el-col :span="15">
                  <div class="label-total">
                    {{ $t("search.accurateColorSearch.compareColors.total") }}
                    {{ this.total }}
                  </div>
                </el-col>
                <el-col :span="15">
                  <div style="margin-left: 12px">
                    <div style="margin-top: 5px; min-width: 420px" v-for="(item, index) in diffTolerance" :key="index">
                      <i class="el-icon-circle-plus-outline clickable" style="margin-top: 8px; margin-right: -20px"
                        @click="addTolerance" :title="$t(
                          'search.accurateColorSearch.compareColors.addTolerance'
                        )
                          " v-if="index == 0"></i>
                      <i style="margin-top: 8px; margin-right: 8px" class="el-icon-delete clickable"
                        @click="removeTolerance(item)" v-if="index != 0"></i>
                      <div v-else style="margin-right: 28px; display: inline"></div>
                      <div class="label">
                        {{ $t("common.color.colorDifferenceFormula") }}
                      </div>
                      <el-select v-model="item.diffFormula" placeholder="色差公式" @change="compareColors"
                        style="width: 130px" size="mini">
                        <el-option v-for="item in colorDifferenceFormulas" :key="item.value" :label="item.name"
                          :value="item.value"></el-option>
                      </el-select>
                      <div class="label" style="margin-left: 5px">
                        {{
                          $t(
                            "search.accurateColorSearch.compareColors.acceptedRange"
                          )
                        }}
                      </div>
                      <el-input-number :precision="2" :step="0.5" controls-position="right" v-model="item.tolerance"
                        @change="compareColors" style="width: 92px" size="mini"></el-input-number>
                    </div>
                  </div>
                </el-col>
                <el-col :span="9" style="text-align: right">
                  <div style="margin-left: 12px">
                    <div v-for="(item, index) in illObs" :key="index" style="margin-top: 5px">
                      <i class="el-icon-circle-plus-outline clickable" style="margin-top: 8px; margin-right: -18px"
                        @click="addIllObs" :title="$t(
                          'search.accurateColorSearch.compareColors.addComparisonMode'
                        )
                          " v-if="index == 0"></i>
                      <i style="margin-top: 8px; margin-right: 10px" class="el-icon-delete clickable"
                        @click="removeIllObs(item.name)" v-if="index != 0"></i>
                      <div v-else style="margin-right: 28px; display: inline"></div>

                      <el-select v-model="item.illuminant" size="mini" style="width: 70px" @change="compareColors">
                        <el-option v-for="item in illuminantList" :key="item" :label="item" :value="item"></el-option>
                      </el-select>
                      /
                      <el-select v-model="item.observerAngle" size="mini" style="width: 85px" @change="compareColors">
                        <el-option v-for="item in observerAngleList" :key="item" :label="item"
                          :value="item"></el-option>
                      </el-select>
                    </div>
                  </div>
                </el-col>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="2" style="padding-top: 12px; direction: rtl; font-size: 13px">
              {{ $t("search.accurateColorSearch.compareColors.standard") }}
            </el-col>
            <el-col :span="14" :title="currentStandard.name">
              <div class="sample-name draggable-sample" style="
                  padding: 8px;
                  width: 100%;
                  display: block;
                  background-color: white;
                " draggable @dragstart="startDrag($event, 0)" @drop="onDrop($event, 0)" @dragenter="dragEnter"
                @dragover="dragOver" @dragleave="dragLeave" @mouseover="mouseoverSample = 0"
                @mouseout="mouseoverSample = null" @blur="editingSample = null">
                <i class="el-icon-rank move hidden-icon"
                  :title="$t('search.accurateColorSearch.compareColors.move')"></i>
                <span class="id-dot list-dot" :style="{
                  backgroundColor: standard
                    ? colorAll[Number(standard.no)]
                    : 'yellow',
                }"></span>
                <input type="text" class="sample-text inputName" style="margin: 0px" :value="currentStandard.name"
                  ref="input0" v-if="editingSample == 0" @blur="getText" />
                <span class="sample-text" v-else>
                  {{ currentStandard.name }}
                </span>
                <i class="el-icon-check clickable" style="margin-right: 4px; margin-left: 4px"
                  :title="$t('common.buttons.edit')" @click="saveSampleName(0)" v-if="editingSample == 0"></i>
                <i class="el-icon-edit clickable hidden-icon" style="margin-right: 4px; margin-left: 4px"
                  :title="$t('common.buttons.edit')" @click="editingSample = 0" v-else></i>
                <i class="el-icon-close clickable" :title="$t('common.buttons.cancel')" @click="editingSample = null"
                  v-if="editingSample == 0"></i>
                <i class="el-icon-delete clickable hidden-icon" :title="$t('common.buttons.remove')"
                  @click="removeSample(0)" v-else></i>
              </div>
            </el-col>
            <el-col :span="7" :offset="1" class="comparison-zone comparison-header">
              <div>
                {{ $t("search.accurateColorSearch.compareColors.standard") }}
              </div>
              <div>
                {{ $t("search.accurateColorSearch.compareColors.sample") }}
              </div>
            </el-col>
          </el-row>

          <div class="scroll-samples">
            <el-row v-for="(item, index) in currentSamples" :key="item.id">
              <el-col :span="2" v-if="index == 0" style="padding-top: 12px; direction: rtl; font-size: 13px">
                {{ $t("search.accurateColorSearch.compareColors.sample") }}
              </el-col>

              <el-col :offset="index == 0 ? 0 : 2" :span="14" :title="item.name">
                <div class="sample-name draggable-sample" style="padding: 8px; width: 100%" draggable
                  @dragstart="startDrag($event, item.id)" @drop="onDrop($event, item.id)" @dragover="dragOver"
                  @dragenter="dragEnter" @dragleave="dragLeave" @mouseover="mouseoverSample = item.id"
                  @mouseout="mouseoverSample = null">
                  <i class="el-icon-rank move hidden-icon"
                    :title="$t('search.accurateColorSearch.compareColors.move')"></i>
                  <!-- <i
                    class="el-icon-aim clickable make-standard hidden-icon"
                    @click="swapStandard(item.id)"
                    :title="
                      $t(
                        'search.accurateColorSearch.compareColors.makeStandard'
                      )
                    "
                  ></i> -->
                  <span class="id-dot list-dot" :style="{
                    backgroundColor: colorAll[Number(item.no)]
                      ? colorAll[Number(item.no)]
                      : 'yellow',
                  }"></span>
                  <input type="text" class="sample-text inputName" style="margin: 0px" :value="item.name"
                    :ref="'input' + item.id" v-if="editingSample == item.id" @blur="getText" />
                  <span class="sample-text" v-else>
                    {{ item.name }}
                  </span>
                  <i class="el-icon-check clickable" style="margin-right: 4px; margin-left: 4px"
                    :title="$t('common.buttons.edit')" @click="saveSampleName(item.id)"
                    v-if="editingSample == item.id"></i>
                  <i class="el-icon-edit clickable hidden-icon" style="margin-right: 4px; margin-left: 4px"
                    :title="$t('common.buttons.edit')" @click="editingSample = item.id" v-else></i>

                  <i class="el-icon-close clickable" :title="$t('common.buttons.cancel')" @click="editingSample = null"
                    v-if="editingSample == item.id"></i>
                  <i class="el-icon-delete clickable hidden-icon" :title="$t('common.buttons.remove')"
                    @click="removeSample(item.id)" v-else></i>
                </div>
              </el-col>
              <el-col :offset="1" :span="7" :class="index == currentSamples.length - 1
                ? 'comparison-zone comparison-last-row'
                : 'comparison-zone'
                ">
                <div class="color-comparison-square" :style="{ backgroundColor: currentStandard.hex }"></div>
                <div class="color-comparison-square" :style="{ backgroundColor: item.hex }"></div>
              </el-col>
            </el-row>
          </div>
          <el-row>
            <el-col :span="14" :offset="2">
              <el-upload action="#" :on-remove="handleRemove" :file-list="fileList" :before-upload="beforeUpload"
                :on-change="clearFileChange" :http-request="uploadQTXFilesAdd" :show-file-list="false" style="
                  margin: 10px;
                  border: 1px dashed #c0c0c0;
                  display: inline-block;
                  text-align: center;
                  font-size: 12px;
                  padding: 3px;
                  color: black;
                  width: 80%;
                ">
                <div class="clickable">
                  <i class="el-icon-circle-plus-outline clickable"></i>
                  {{ $t("search.compareColors.addSamples") }}
                </div>
              </el-upload>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="11">
          <div class="graph-pane">
            <center>
              <div class="tab-header">
                <div class="tab-label clickable" @click="
                  graphShown = 'labGraph';
                refreshGraphs();
                " :class="graphShown == 'labGraph' ? 'selected-tab' : undefined">
                  <i class="el-icon-s-help"></i>
                  {{
                    $t(
                      "search.accurateColorSearch.compareColors.labComparisonGraph"
                    )
                  }}
                </div>
                <div class="tab-label clickable" @click="
                  graphShown = 'spectroGraph';
                refreshGraphs();
                " :class="graphShown == 'spectroGraph' ? 'selected-tab' : undefined
                  ">
                  <i class="el-icon-s-marketing"></i>
                  {{
                    $t(
                      "search.accurateColorSearch.compareColors.spectralComparisonGraph"
                    )
                  }}
                </div>
              </div>
              <div class="tab-content" :style="{
                display: graphShown == 'labGraph' ? 'block' : 'none',
              }" style="position: relative">
                <canvas ref="labCanvas" :width="labGraphWidth" :height="labGraphHeight" style="
                    z-index: 0;
                    position: absolute;
                    top: 63px;
                    right: 117px;
                  "></canvas>
                <div class="zoom-menu" style="z-index: 1000">
                  <i class="el-icon-zoom-in clickable" @click="zoomIn" :title="$t('common.color.zoomIn')"></i>
                  <i class="el-icon-zoom-out clickable" @click="zoomOut" :title="$t('common.color.zoomOut')"></i>
                </div>
                <div class="echars">
                  <div id="labGraph" ref="labGraph" class="echarts-chart_4"></div>
                  <div id="labGraphY" ref="labGraphY" class="echarts-chart_5"></div>
                </div>
              </div>
              <div class="tab-content" :style="{
                display: graphShown == 'spectroGraph' ? 'block' : 'none',
              }">
                <div ref="chart" id="spectroGraph" class="echarts-chart_spectro"></div>
              </div>
            </center>
          </div>
        </el-col>
      </el-row>

      <el-table :data="samplesComparisonTableData" style="min-width: 1000px" :span-method="objectSpanMethod"
        ref="comparisonTable" max-height="450" :row-class-name="tableRowClassName" @sort-change="handleSortChange">
        <el-table-column prop="name" fixed :label="$t('search.accurateColorSearch.compareColors.sample')"
          :min-width="280" show-overflow-tooltip sortable="custom">
          <template slot-scope="scope">
            <div @mouseover="mouseoverSample = scope.row.id" @mouseout="mouseoverSample = null"
              style="height: 100%; width: 100%">
              <div class="id-dot" :style="{
                backgroundColor: colorAll[Number(scope.row.no)]
                  ? colorAll[Number(scope.row.no)]
                  : 'yellow',
              }"></div>
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="120">
          <template slot="header">
            <!-- <i
              class="el-icon-circle-plus-outline clickable"
              @click="addIllObsVisible = true"
            ></i> -->

            {{
              $t("common.color.illuminantShort") +
              "/" +
              $t("common.color.observerAngleShort")
            }}
          </template>
          <template slot-scope="scope"><span class="table-illobs">
              {{
                scope.row.illObs.illuminant +
                "/" +
                scope.row.illObs.observerAngle
              }}
              <!-- <i
                class="el-icon-delete clickable hidden-icon"
                @click="removeIllObs(scope.row.illObs.name)"
              ></i> -->
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="l" label="L*" width="65" v-if="showAbsValues" sortable="custom">
        </el-table-column>
        <el-table-column prop="a" label="a*" width="65" v-if="showAbsValues" sortable="custom">
        </el-table-column>
        <el-table-column prop="b" label="b*" width="65" v-if="showAbsValues" sortable="custom">
        </el-table-column>
        <el-table-column prop="c" label="C*" width="65" v-if="showAbsValues" sortable="custom">
        </el-table-column>
        <el-table-column prop="h" label="h*" width="70" v-if="showAbsValues" sortable="custom">
        </el-table-column>
        <el-table-column prop="dl" label="ΔL*" width="70" v-if="showDeltas" sortable="custom">
        </el-table-column>
        <el-table-column prop="da" label="Δa*" width="70" v-if="showDeltas" sortable="custom">
        </el-table-column>
        <el-table-column prop="db" label="Δb*" width="70" v-if="showDeltas" sortable="custom">
        </el-table-column>
        <el-table-column prop="dc" label="ΔC*" width="70" v-if="showDeltas" sortable="custom">
        </el-table-column>
        <el-table-column prop="dh" label="ΔH*" width="70" v-if="showDeltas" sortable="custom">
        </el-table-column>
        <el-table-column prop="whiteness" label="WI CIE" width="90" v-if="showWi" sortable="custom">
        </el-table-column>
        <el-table-column prop="tint" label="Tint" width="75" v-if="showTint" sortable="custom">
          <template slot-scope="scope">
            <div :style="{ color: (scope.row.tint >= 3 || scope.row.tint <= -3) ? 'red' : 'grey' }">
              {{ scope.row.tint }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="de00" label="ΔE00" width="80" sortable="custom">
          <template slot-scope="scope">
            <div :class="containsDE00
              ? scope.row.de00 < toleranceObj.dE00
                ? 'de-pass'
                : 'de-fail'
              : 'de-other'
              ">
              {{ scope.row.de00 }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="de94" label="ΔE94" width="80" v-if="showE94" sortable="custom">
          <template slot-scope="scope">
            <div :class="containsDE94
              ? scope.row.de94 < toleranceObj.dE94
                ? 'de-pass'
                : 'de-fail'
              : 'de-other'
              ">
              {{ scope.row.de94 }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="deab" label="ΔE*ab" width="85" v-if="showEab" sortable="custom">
          <template slot-scope="scope">
            <div :class="containsDEab
              ? scope.row.deab < toleranceObj.dEab
                ? 'de-pass'
                : 'de-fail'
              : 'de-other'
              ">
              {{ scope.row.deab }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="decmc" label="ΔECMC" width="100" sortable="custom">
          <template slot-scope="scope">
            <div :class="containsDECMC
              ? scope.row.decmc < toleranceObj.dECMC
                ? 'de-pass'
                : 'de-fail'
              : 'de-other'
              ">
              {{ scope.row.decmc }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" v-if="showStatus" :label="$t('search.accurateColorSearch.compareColors.status')"
          width="90" sortable="custom">
          <template slot-scope="scope">
            <div style="font-weight: bold; color: red" v-if="scope.row.status == 0">
              {{ $t("search.accurateColorSearch.compareColors.fail") }}
            </div>
            <div style="font-weight: bold; color: green" v-if="scope.row.status == 1">
              {{ $t("search.accurateColorSearch.compareColors.pass") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="colorBias" width="210" v-if="showColorBias">
          <template slot="header">
            {{ $t("search.accurateColorSearch.compareColors.colorBias") }}
          </template>
          <template slot-scope="scope">
            <div style="line-height: 1.25; margin-top: 0px; margin-bottom: 0px">
              <div class="color-bias black" v-if="scope.row.dl < '0'">
                {{ $t("common.color.colorDifference.darker") }}
              </div>
              <div class="color-bias white" v-if="scope.row.dl > '0'">
                {{ $t("common.color.colorDifference.lighter") }}
              </div>
              <div class="color-bias green" v-if="scope.row.da < '0'">
                {{ $t("common.color.colorDifference.greenBias") }}
              </div>
              <div class="color-bias red" v-if="scope.row.da > '0'">
                {{ $t("common.color.colorDifference.redBias") }}
              </div>
              <div class="color-bias blue" v-if="scope.row.db < '0'">
                {{ $t("common.color.colorDifference.blueBias") }}
              </div>
              <div class="color-bias yellow" v-if="scope.row.db > '0'">
                {{ $t("common.color.colorDifference.yellowBias") }}
              </div>
              <br />
              <div class="color-bias" v-if="scope.row.dc > '0'">
                {{ $t("common.color.colorDifference.strongerColor") }}
              </div>
              <div class="color-bias" v-if="scope.row.dc < '0'">
                {{ $t("common.color.colorDifference.weakerColor") }}
              </div>
              <div class="color-bias" v-if="scope.row.dh">
                {{
                  scope.row.dh < 0 ? getColorText((scope.row.h * Math.PI) / 180)[0] : getColorText((scope.row.h * Math.PI)
                    / 180)[1] }} </div>
              </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="position: absolute; right: 70px; margin-top: 05px">
        <el-popover placement="top" width="235" v-model="editViewPopoverVisible">
          <div style="margin: 0">
            <el-checkbox :checked="showAbsValues" :label="$t('search.compareColors.displayMode.absoluteValues')"
              @change="
                showAbsValues = !showAbsValues;
              setCookies();
              " size="mini"></el-checkbox>
            <div>
              <el-checkbox :checked="showDeltas" :label="$t('search.compareColors.displayMode.deltas')" size="mini"
                @change="
                  showDeltas = !showDeltas;
                setCookies();
                "></el-checkbox>
            </div>
            <div>
              <el-checkbox :checked="showWi" label="WI CIE" size="mini" @change="
                showWi = !showWi;
              setCookies();
              "></el-checkbox>
            </div>
            <div>
              <el-checkbox :checked="showTint" label="Tint" size="mini" @change="
                showTint = !showTint;
              setCookies();
              "></el-checkbox>
            </div>
            <div>
              <el-checkbox :checked="showE94" label="ΔE94" size="mini" @change="
                showE94 = !showE94;
              setCookies();
              "></el-checkbox>
            </div>
            <div>
              <el-checkbox :checked="showEab" label="ΔE*ab" size="mini" @change="
                showEab = !showEab;
              setCookies();
              "></el-checkbox>
            </div>
            <div>
              <el-checkbox :checked="showStatus" :label="$t('search.accurateColorSearch.compareColors.status')"
                size="mini" @change="
                  showStatus = !showStatus;
                setCookies();
                "></el-checkbox>
            </div>
            <div>
              <el-checkbox :checked="showColorBias" :label="$t('search.compareColors.displayMode.colorBias')"
                size="mini" @change="
                  showColorBias = !showColorBias;
                setCookies();
                "></el-checkbox>
            </div>
          </div>
          <i class="el-icon-s-tools clickable" style="font-size: 12px" slot="reference">&nbsp; {{
            $t("search.compareColors.displayMode") }}
          </i>
        </el-popover>
      </div>
      <div style="display: flex; justify-content: space-between; margin-top: 60px">
        <div style="margin-top: 20px">
          {{
            $t("search.accurateColorSearch.compareColors.allRightsReservedX", {
              x: "e.dye",
            })
          }}
        </div>
        <div>
          <!-- <el-button @click="exportPdf">{{ $t("search.accurateColorSearch.compareColors.exportPdf") }}
          </el-button> -->
          <el-button @click="exportExcel">{{ $t("search.accurateColorSearch.compareColors.exportExcel") }}
          </el-button>
          <el-button @click="exportQTX">{{ $t("search.accurateColorSearch.compareColors.exportQTX") }}
          </el-button>
        </div>
      </div>
    </div>

    <el-dialog :title="$t('search.accurateColorSearch.compareColors.addComparisonMode')"
      :visible.sync="addIllObsVisible" width="650px" :before-close="handleClose" append-to-body>
      <div class="comparison-params-dialog">
        <div>
          {{ $t("common.color.illuminant") }}
          <illuminant-selector size="mini" width="140" @change="selectorIllChanged" class="select" />
        </div>
        <div>
          {{ $t("common.color.observerAngle") }}
          <observer-selector size="mini" width="140" @change="selectorObsChanged" class="select" />
        </div>
        <div>
          <el-button type="primary" @click="confirmAddObsIll" size="small">{{ $t("common.buttons.add") }}
          </el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog :title="isAdd
      ? $t('search.compareColors.addSamples.addSamplesToCompare')
      : $t('search.compareColors.addSamples.selectSamplesToCompare')
      " :visible.sync="importSamplesVisible" width="390px" append-to-body>
      <div class="import-samples-dialog">
        <div style="text-decoration: italics; margin-bottom: 10px">
          {{ fileList.length > 0 ? fileList[0].name : "" }}
        </div>
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAllFiles" @change="handleCheckAllChange">{{
          $t("search.compareColors.addSamples.checkAll") }}</el-checkbox>
        <div class="select-files-container">
          <el-checkbox-group v-model="checkedFiles" @change="handleCheckedFilesChange">
            <div class="select-files" style="height: 30px" v-for="(item, index) in uploadingQTXData" :key="index">
              <el-checkbox :label="index" class="select-files-checkbox">
                <div class="select-files-text">
                  {{ uploadingQTXData[index].name }}
                </div>
                <div class="select-files-color" :style="{ backgroundColor: uploadingQTXData[index].hex }"></div>
              </el-checkbox>
            </div>
          </el-checkbox-group>
        </div>

        <el-button @click="importSamplesVisible = false" size="small"
          style="position: absolute; right: 80px; bottom: 12px">{{ $t("common.buttons.cancel") }}</el-button>

        <el-button type="primary" @click="confirmAddSamples" size="small"
          style="position: absolute; right: 10px; bottom: 12px">{{ $t("common.buttons.add") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as echarts from "echarts";
import observerSelector from "../../components/observerSelector.vue";
import IlluminantSelector from "../../components/illuminantSelector.vue";
import {
  getColorimetricReport,
  parsingDataForQtx,
} from "@/api/modules/product";
import Cookies from "js-cookie";
import colorCompareStore from "@/store/modules/colorCompareStore";
import { axiosDownFile } from "@/utils/index";
import { getObserverAngleTextsApi } from "@/api/modules/colorbook";
import { getIlluminantTextsApi } from "@/api/modules/colorbook";
import html2canvas from 'html2canvas';
export default {
  components: { observerSelector, IlluminantSelector },
  name: "SingleYAxisScatterChart",
  data() {
    return {
      total: 0,
      addIllObsCount: 1,
      initiated: false,
      compareData: colorCompareStore.data,
      standardLabData: [],
      samplesComparisonData: [],
      addIllObsVisible: false,
      importSamplesVisible: false,
      visible: false,
      editViewPopoverVisible: false,
      showAbsValues: true,
      showDeltas: true,
      showColorBias: true,
      showWi: false,
      showTint: false,
      showE94: false,
      showEab: false,
      showStatus: true,
      colorAll: [
        "black",
        "green",
        "red",
        "blue",
        "orange",
        "purple",
        "grey",
        "aqua",
        "brown",
        "#F0F8FF",
        "#8a2be2",
        "chocolate",
        "cyan",
        "darkseagreen",
        "fuschia",
        "gainsboro",
        "goldenrod",
        "greenyellow",
        "orangered",
        "khaki",
      ],
      observerAngleList: [],
      illuminantList: [],
      colorDifferenceFormulas: [
        {
          value: "dE00",
          name: "CIE2000(2:1:1)",
        },
        {
          value: "dE94",
          name: "CIE1994",
        },
        {
          value: "dEab",
          name: "CIE1976",
        },
        {
          value: "dECMC",
          name: "CMC(2:1)",
        },
      ],
      diffTolerance: [
        {
          diffFormula: "dE00",
          tolerance: 2,
        },
      ],
      zoomLevel: 1,
      openObsIllSelector: {
        observerAngle: "D65",
        illuminant: "10 deg",
      },
      illObs: [
        {
          name: "D65/10 deg",
          illuminant: "D65",
          observerAngle: "10 deg",
        },
      ],
      currentIllObsName: "D65/10 deg",
      defaultObs: "10 deg",
      defaultIll: "D65",
      brandLogo: localStorage.getItem("brandLogo"),
      graphShown: "labGraph",
      labGraphHeight: 268,
      labGraphWidth: 340,
      canvas: null,
      fileList: [],
      previousFile: "",
      uploadingQTXData: [],
      checkAllFiles: true,
      checkedFiles: [],
      isIndeterminate: false,
      isAdd: false,
      abChart: false,
      lChart: false,
      spectroGraph: false,
      mouseoverSample: null,
      editingSample: null,
      editingSampleName: null,
      comparisonSettingsVisible: false,
    };
  },
  computed: {
    standard() {
      return this.compareData.standard;
    },
    defaultObsIllName() {
      return this.defaultIll + "/" + this.defaultObs;
    },
    originalData() {
      if (this.samplesComparisonData.length > 0) {
        var data = [];
        this.samplesComparisonData.forEach((item) => {
          data.push({
            value: [item.da, item.db],
            name: item.name,
            illObs: item.illObs,
            id: item.id,
          });
        });
        return data;
      } else {
        return [];
      }
    },
    labGraphAbData() {
      let data = this.originalData;
      if (
        this.originalData &&
        this.originalData.length > 0 &&
        this.originalData[0].illObs
      ) {
        const filteredList = this.originalData.filter(
          (item) => item.illObs.name == this.currentIllObsName
        );
        data = filteredList ? filteredList : this.originalData;
      }

      data.unshift({
        value: [0, 0],
        name: "STD",
        id: this.standard.id,
        no: 0,
      });
      return data;
    },
    originalDataY() {
      if (this.samplesComparisonData.length > 0) {
        var data = [];
        this.samplesComparisonData.forEach((item) => {
          data.push({
            value: [0, item.dl],
            name: item.name,
            illObs: item.illObs,
            id: item.id,
          });
        });
        return data;
      } else {
        return [];
      }
    },
    labGraphLData() {
      let data = this.originalDataY;
      if (
        this.originalDataY &&
        this.originalDataY.length > 0 &&
        this.originalDataY[0].illObs
      ) {
        const filteredList = this.originalDataY.filter(
          (item) => item.illObs.name == this.currentIllObsName
        );
        data = filteredList ? filteredList : this.originalDataY;
      }
      data.unshift({
        value: [0, 0],
        name: "STD",
        id: this.standard.id,
        no: 0,
      });
      return data;
    },
    currentIllObs() {
      return this.illObs.filter((item) => item.name == this.currentIllObsName);
    },
    currentStandard() {
      if (this.standardLabData && this.standardLabData.length > 0) {
        const filteredList = this.standardLabData.filter(
          (item) => item.illObs.name == this.currentIllObsName
        )[0];
        return filteredList ? filteredList : { name: "Error", hex: "#000000" };
      } else {
        return { name: "Error", hex: "#000000" };
      }
    },
    currentSamples() {
      if (this.samplesComparisonData && this.samplesComparisonData.length > 0) {
        return this.samplesComparisonData.filter(
          (item) => item.illObs.name == this.currentIllObsName
        );
      } else {
        return { name: "Error", hex: "#000000" };
      }
    },
    containsDE00() {
      return (
        this.diffTolerance.filter((item) => item.diffFormula == "dE00").length >
        0
      );
    },
    containsDE94() {
      return (
        this.diffTolerance.filter((item) => item.diffFormula == "dE94").length >
        0
      );
    },
    containsDEab() {
      return (
        this.diffTolerance.filter((item) => item.diffFormula == "dEab").length >
        0
      );
    },
    containsDECMC() {
      return (
        this.diffTolerance.filter((item) => item.diffFormula == "dECMC")
          .length > 0
      );
    },
    remainingDiffFormulas() {
      let usedDiffFormulas = [];
      this.diffTolerance.forEach((tolerance) => {
        this.colorDifferenceFormulas.forEach((formula) => {
          if (tolerance.diffFormula == formula.value) {
            // 已经被用过
            usedDiffFormulas.push(formula);
          }
        });
      });
      return this.colorDifferenceFormulas.filter(
        (item) => !usedDiffFormulas.includes(item, 0)
      );
    },
    axisAndIntervalSize() {
      let axisSize = this.zoomLevel * 5;

      let intervalSize = this.zoomLevel;

      if (intervalSize > 1) {
        intervalSize = Math.round(intervalSize);
      } else {
        intervalSize = Math.round(intervalSize * 10) / 10;
      }

      if (axisSize > 1) {
        axisSize = Math.round(axisSize);
      } else {
        axisSize = Math.round(axisSize * 10) / 10;
      }
      return [axisSize, intervalSize];
    },
    samplesComparisonTableData() {
      // add this.standardLabData

      const tableData = this.standardLabData;
      if (this.showAbsValues) {
        return tableData.concat(this.samplesComparisonData);
      } else {
        return this.samplesComparisonData;
      }
    },
    toleranceObj() {
      let toleranceObj = {
        dE00: undefined,
        dEab: undefined,
        dE94: undefined,
        dECMC: undefined,
      };
      this.diffTolerance.filter((obj) => {
        toleranceObj[obj.diffFormula] = obj.tolerance;
      });
      console.log(toleranceObj);
      return toleranceObj;
    },
    orderedColors() {
      let orderedColors = [];
      orderedColors.push(this.colorAll[this.standard.no]);
      this.compareData.samples.forEach((sample) => {
        orderedColors.push(this.colorAll[sample.no]);
      });
      return orderedColors;
    },
    abChartOption() { },
    lChartOption() { },
  },
  watch: {
    mouseoverSample(oldData, newData) {
      this.initLabGraph(this.mouseoverSample);
    },
    standard(oldData, newData) {
      if (!this.initiated) {
        this.init();
      } else {
        this.compareColors();
      }
    },

    // 监听标准数据的变化
    standardLabData: {
      handler(newVal) {
        this.updateTotal();
      },
      deep: true, // 深度监听，确保数组内部变化也能触发
    },
    // 监听样本数据的变化
    samplesComparisonData: {
      handler(newVal) {
        this.updateTotal();
      },
      deep: true, // 深度监听
    },
  },
  mounted() {
    if (
      colorCompareStore.data &&
      colorCompareStore.data.standard &&
      colorCompareStore.data.samples &&
      colorCompareStore.data.samples.length > 0
    ) {
      this.init();
    }

    // 初始化时计算 total
    this.updateTotal();
  },
  methods: {
    updateTotal() {
      this.total = 0;
      const standardCount = this.standardLabData ? this.standardLabData.length : 0;
      const sampleCount = this.samplesComparisonData ? this.samplesComparisonData.length : 0;
      this.total = (standardCount + sampleCount) / this.illObs.length;
    },
    // handleSortChange({ column, prop, order }) {
    //   if (this.illObs.length > 0 && this.illObs.length < 2) {
    //     // 移除第一条数据
    //     const removedData = this.samplesComparisonTableData.shift();

    //     // 排序数据
    //     const sortedData = this.samplesComparisonTableData.sort((a, b) => {
    //       if (order === 'ascending') {
    //         return a[prop] - b[prop];
    //       } else if (order === 'descending') {
    //         return b[prop] - a[prop];
    //       }
    //       return 0;
    //     });

    //     // 将移除的数据放到排序后的第一条位置
    //     if (removedData) {
    //       sortedData.unshift(removedData);
    //     }

    //     // 更新表格数据
    //     this.samplesComparisonTableData = sortedData;
    //   } else {
    //     this.$message.error(
    //       this.$t("tips.message.sort")
    //     );
    //   }

    // },
    handleSortChange({ column, prop, order }) {
      if (this.illObs.length > 0 && this.illObs.length < 2) {
        // 移除第一条数据
        const removedData = this.samplesComparisonTableData.shift();

        // 排序数据
        const sortedData = this.samplesComparisonTableData.sort((a, b) => {
          let comparison = 0;

          // 如果属性是 `name`（对应第一列），使用 localeCompare 排序
          if (prop === 'name') {
            comparison = a.name.localeCompare(b.name);
          } else {
            // 否则根据数值排序
            comparison = a[prop] - b[prop];
          }

          return order === 'ascending' ? comparison : -comparison;
        });

        // 将移除的数据放到排序后的第一条位置
        if (removedData) {
          sortedData.unshift(removedData);
        }

        // 更新表格数据
        this.samplesComparisonTableData = sortedData;
      } else {
        this.$message.error(this.$t("tips.message.sort"));
      }
    },
    // 将所有图表保存为图片并上传到后端
    async saveGraphsAsImages() {
      await this.$nextTick(); // 确保 DOM 更新
      const graphIds = ['labGraph', 'labGraphY', 'spectroGraph'];
      const formData = new FormData();

      // 处理图表
      for (const graphId of graphIds) {
        const chartRef = this.$refs[graphId];
        if (chartRef) {
          const chart = echarts.getInstanceByDom(chartRef);
          if (chart) {
            console.log(`Processing chart: ${graphId}`);
            const imgData = chart.getDataURL({
              type: 'png',
              pixelRatio: 2,
              backgroundColor: '#fff',
            });
            const byteString = atob(imgData.split(',')[1]);
            const mimeString = imgData.split(',')[0].split(':')[1].split(';')[0];
            const ab = new Uint8Array(byteString.length);
            for (let i = 0; i < byteString.length; i++) {
              ab[i] = byteString.charCodeAt(i);
            }
            const blob = new Blob([ab], { type: mimeString });
            formData.append('images', blob, `${graphId}.png`);
          } else {
            console.warn(`Chart instance not found for ref ${graphId}`);
          }
        } else {
          console.warn(`Element with ref ${graphId} not found.`);
        }
      }

      // 处理表格
      const tableRefComponent = this.$refs.comparisonTable;
      const tableRef = tableRefComponent ? tableRefComponent.$el : null; // 获取 DOM 元素
      console.log('调试：开始截图1', tableRef);

      if (tableRef && tableRef instanceof HTMLElement) {
        console.log('调试：开始截图2');

        // 暂时设置表格高度为所有内容的高度，确保完整显示
        const originalHeight = tableRef.style.height;
        const originalMaxHeight = tableRef.style.maxHeight;
        tableRef.style.height = 'auto';
        tableRef.style.maxHeight = 'none';

        // 确保表格元素附加在 DOM 上
        let attached = document.body.contains(tableRef);
        let retryCount = 0;
        while (!attached && retryCount < 10) {
          await new Promise((resolve) => setTimeout(resolve, 200)); // 增加延迟到200ms并增加重试次数
          attached = document.body.contains(tableRef);
          retryCount++;
          console.log(`调试：尝试附加 DOM 第 ${retryCount} 次`);
        }

        if (!attached) {
          console.error('Table element is still not attached to the DOM after retries');
          return;
        }

        // 截图表格并封装 Blob
        try {
          console.log('调试：开始截图4');
          const canvas = await html2canvas(tableRef, { scale: 2 });
          console.log('Canvas generated, attempting to create Blob');

          const blob = await new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
              if (blob) resolve(blob);
              else reject(new Error('Blob creation failed'));
            }, 'image/png');
          });

          formData.append('images', blob, `comparisonTable.png`);
          console.log('调试：Blob appended to formData');
        } catch (error) {
          console.error('Error capturing table with html2canvas:', error);
        } finally {
          // 还原表格的原始高度设置
          tableRef.style.height = originalHeight;
          tableRef.style.maxHeight = originalMaxHeight;
        }
      } else {
        console.log('调试：开始截图5');
        console.warn('Table element not found or is not an HTML element');
      }

      // 上传图像并生成 PDF
      try {
        await axiosDownFile('/api/c/product/uploadImages', formData);
      } catch (error) {
        console.error('Error uploading images or generating PDF:', error);
      }
    },
    illObsChanged() {
      this.setCookies();
      this.refreshGraphs();
    },
    compareColors() {
      var params = {
        standard: this.compareData.standard,
        samples: this.compareData.samples,
        illObs: this.illObs,
        diffTolerance: this.diffTolerance,
      };

      getColorimetricReport(params).then((res) => {
        if (res) {
          var samplesComparisonData = res.samples;
          samplesComparisonData.sort(
            (firstItem, secondItem) =>
              Number(firstItem.id) - Number(secondItem.id)
          );
          this.standardLabData = res.standard;
          this.samplesComparisonData = samplesComparisonData;

          setTimeout(() => {
            this.initLabGraph();
            this.initSpectroGraph();
            this.initCanvas();
          }, 500);
          this.setCookies();
        }
      });
      this.$forceUpdate();
    },
    setCookies() {
      const userId = sessionStorage.getItem("userId");
      Cookies.set("diffTolerance", JSON.stringify(this.diffTolerance));
      Cookies.set(
        userId + "openObsIllSelector",
        JSON.stringify(this.openObsIllSelector)
      );
      Cookies.set(userId + "illObs", JSON.stringify(this.illObs));
      Cookies.set(userId + "currentIllObsName", this.currentIllObsName);

      Cookies.set(userId + "showAbsValues", this.showAbsValues);
      Cookies.set(userId + "showDeltas", this.showDeltas);
      Cookies.set(userId + "showColorBias", this.showColorBias);
    },
    removeIllObs(illObs) {
      if (this.illObs && this.illObs.length > 1) {
        this.illObs = this.illObs.filter((obj) => obj.name != illObs);

        if (this.currentIllObsName == illObs) {
          this.currentIllObsName = this.illObs[this.illObs.length - 1].name;
        }
        this.compareColors();
      } else {
        this.$message.error(
          this.$t(
            "search.accurateColorSearch.compareColors.lastIllObsCannotRemoved"
          )
        );
      }
    },
    removeTolerance(tolerance) {
      this.diffTolerance = this.diffTolerance.filter(
        (item) => item != tolerance
      );
      this.compareColors();
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % this.illObs.length === 0) {
          return {
            rowspan: this.illObs.length,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    selectorObsChanged(obsAngle) {
      this.openObsIllSelector.observerAngle = obsAngle;
      console.log(this.openObsIllSelector);
    },
    selectorIllChanged(ill) {
      this.openObsIllSelector.illuminant = ill;
      console.log(this.openObsIllSelector);
    },
    confirmAddObsIll() {
      var currentValue =
        this.openObsIllSelector.illuminant +
        "/" +
        this.openObsIllSelector.observerAngle;
      if (this.illObs.filter((obj) => obj.name == currentValue).length > 0) {
        this.$message.error(
          this.$t("search.accurateColorSearch.compareColors.illObsAlreadyAdded")
        );
      } else {
        this.illObs.push({
          name: currentValue,
          illuminant: this.openObsIllSelector.illuminant,
          observerAngle: this.openObsIllSelector.observerAngle,
        });
        this.addIllObsVisible = false;
        this.currentIllObsName = currentValue;
        this.compareColors();
      }
    },
    handleClose() {
      this.openObsIllSelector = {
        observerAngle: this.defaultObs,
        illuminant: this.defaultIll,
      };
      this.addIllObsVisible = false;
    },
    addIllObs() {
      this.illObs.push({
        name: this.defaultIll + "/" + this.defaultObs + this.illObs.length,
        illuminant: this.defaultIll,
        observerAngle: this.defaultObs,
      });
      this.compareColors();
    },
    addTolerance() {
      if (this.remainingDiffFormulas && this.remainingDiffFormulas.length > 0) {
        this.diffTolerance.push({
          diffFormula: this.remainingDiffFormulas[0].value,
          tolerance: this.diffTolerance[0].tolerance,
        });
        this.compareColors();
      } else {
        this.$message.error(
          this.$t("search.accurateColorSearch.compareColors.allFormulasUsed")
        );
      }
    },
    makeStepOption(option, data) {
      let series = [];

      data.forEach((item) => {
        series.push({
          name: item.name,
          type: "scatter",
          encode: {
            tooltip: [0, 1],
          },
          data: [item],
          symbolSize: this.mouseoverSample == item.id ? 12 : 7,
        });
      });

      option.options.push({ series: series });
    },
    initLabGraph(hoverId) {
      if (this.abChart && !hoverId) {
        this.abChart.dispose();
        this.lChart.dispose();
      }
      const orderedEchartColors = {
        color: this.orderedColors,
      };
      echarts.registerTheme("customTheme", orderedEchartColors);
      var [axisSize, intervalSize] = this.axisAndIntervalSize;
      this.abChart = echarts.init(this.$refs.labGraph, "customTheme");
      this.lChart = echarts.init(this.$refs.labGraphY, "customTheme");

      var option;
      var optionY;
      var ANIMATION_DURATION_UPDATE = 200;
      var standardText = this.$t(
        "search.accurateColorSearch.compareColors.standard"
      );

      option = {
        timeline: {
          show: false,
          top: "center",
          right: 30,
          height: 120,
          width: 7,
          inverse: true,
          autoPlay: false,
          playInterval: 500,
          orient: "vertical",
          axisType: "category",
          label: {
            formatter: "step {value}",
            position: 10,
          },
          checkpointStyle: {
            animationDuration: ANIMATION_DURATION_UPDATE,
          },
          data: [],
        },
        baseOption: {
          animationDurationUpdate: ANIMATION_DURATION_UPDATE,
          transition: ["shape"],
          tooltip: {
            // trigger: 'axis',
            showDelay: 0,
            formatter: function (params) {
              if (params.value.length > 1) {
                return params.seriesName == "STD"
                  ? standardText
                  : "<b>" +
                  params.seriesName +
                  "</b>:<br>△a: " +
                  params.value[0] +
                  ", △b: " +
                  params.value[1];
              } else {
                return (
                  params.seriesName +
                  ":<br>△a: " +
                  params.name +
                  ", △b: " +
                  params.value
                );
              }
            },
            axisPointer: {
              show: true,
              type: "cross",
              lineStyle: {
                type: "dashed",
                width: 1,
              },
            },
          },
          grid: {},
          xAxis: {
            name: "△a",
            nameTextStyle: {
              fontSize: 16,
              align: "left",
            },
            type: "value",
            // 设置轴的最小值和最大值，使得0点在中间
            min: -axisSize,
            max: axisSize,
            // 控制刻度间距
            interval: intervalSize,
            // 确保轴线通过原点
            axisLine: {
              onZero: true,
              lineStyle: {
                width: "3",
                color: {
                  type: "linear",
                  x: 1,
                  y: 0,
                  x2: 0,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: "red", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "green", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
          },
          yAxis: {
            name: "△b",
            nameTextStyle: {
              fontSize: 16,
              align: "left",
            },
            type: "value",
            // 设置轴的最小值和最大值，使得0点在中间
            min: -axisSize,
            max: axisSize,
            // 控制刻度间距
            interval: intervalSize,
            // 确保轴线通过原点
            axisLine: {
              onZero: true,
              lineStyle: {
                width: "3",
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "yellow", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "blue", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
          },
          series: [
            {
              type: "scatter",
            },
          ],
        },
        options: [],
      };

      optionY = {
        timeline: {
          show: false,
          top: "center",
          right: 30,
          height: 120,
          width: 3,
          inverse: true,
          autoPlay: false,
          playInterval: 500,
          symbol: "none",
          orient: "vertical",
          axisType: "category",
          label: {
            formatter: "step {value}",
            position: 10,
          },
          checkpointStyle: {
            animationDuration: ANIMATION_DURATION_UPDATE,
          },
          data: [],
        },
        baseOption: {
          animationDurationUpdate: ANIMATION_DURATION_UPDATE,
          transition: ["shape"],
          tooltip: {
            // trigger: 'axis',
            showDelay: 0,
            formatter: function (params) {
              if (params.value.length > 1) {
                return params.seriesName == "STD"
                  ? standardText
                  : params.seriesName + ":<br>△L: " + params.value[1];
              } else {
                return params.seriesName + ":<br>△L: " + params.value;
              }
            },
            axisPointer: {
              show: true,
              type: "none",
              axis: "x",
              lineStyle: {
                type: "dashed",
                width: 1,
              },
            },
          },
          grid: {},
          xAxis: {
            show: false,
            name: "",
            type: "value",
            // 设置轴的最小值和最大值，使得0点在中间
            min: -5,
            max: 5,
            // 控制刻度间距
            interval: 5,
            // 确保轴线通过原点
            axisLine: {
              onZero: true,
            },
          },
          yAxis: {
            name: "△L",
            nameTextStyle: {
              fontSize: 16,
              align: "left",
            },
            type: "value",
            // 设置轴的最小值和最大值，使得0点在中间
            min: -axisSize,
            max: axisSize,
            // 控制刻度间距
            interval: intervalSize,
            // 确保轴线通过原点
            axisLine: {
              onZero: true,
              lineStyle: {
                width: "3",
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#ffffff", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#000000", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
          },
          series: [
            {
              type: "scatter",
            },
          ],
        },
        options: [],
      };

      this.makeStepOption(option, this.labGraphAbData);
      this.makeStepOption(optionY, this.labGraphLData);

      option && this.abChart.setOption(option);
      optionY && this.lChart.setOption(optionY);
    },
    makeArrayAndDivide(list) {
      var splitList = list.split(",");
      splitList.forEach(
        (item) => (item = (Number(item.trim()) / 100).toFixed(5))
      );
      return splitList;
    },

    initSpectroGraph() {
      // !!!!! IMPORTANT !!!!!!
      // 如果反射率的数据点数量不都一致，会出现问题
      // BUG！
      if (!this.spectroGraph) {
        this.spectroGraph = this.$echarts.init(
          document.getElementById("spectroGraph"),
          null,
          {
            width: 600,
            height: 400,
          }
        );
      }

      // 数据处理
      var reflectanceData = this.makeArrayAndDivide(
        this.compareData.standard.r
      );

      var reflLow = this.compareData.standard.reflLow;
      var reflInterval = this.compareData.standard.reflInterval;

      var wavelengths = [];
      for (
        var i = reflLow;
        i < reflLow + reflectanceData.length * reflInterval;
        i += reflInterval
      ) {
        wavelengths.push(i + "nm");
      }
      var series = [
        {
          data: reflectanceData,
          type: "line",
          smooth: true,
          name: this.compareData.standard.name,
          itemStyle: {
            // 设置折线节点的样式
            color: "#000000",
          },
        },
      ];

      this.compareData.samples.forEach((sample) => {
        series.push({
          data: this.makeArrayAndDivide(sample.r),
          type: "line",
          smooth: true,
          name: sample.name,
          itemStyle: {
            // 设置折线节点的样式
            normal: {
              color: this.colorAll[Number(sample.no)]
                ? this.colorAll[Number(sample.no)]
                : "yellow",
            },
          },
        });
      });

      let option = null;
      // 曲线表配置
      option = {
        tooltip: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          textStyle: {
            color: "#ffffff",
          },
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        xAxis: {
          type: "category",
          name: this.$t("common.color.spectralGraph.wavelengthShort"),
          data: wavelengths,
        },
        yAxis: {
          type: "value",
          name: this.$t("common.color.spectralGraph.reflectance"),
        },
        dataZoom: [
          // {
          //   type: "slider",
          //   xAxisIndex: 0,
          //   filterMode: "none",
          // },
          {
            type: "slider",
            yAxisIndex: 0,
            filterMode: "none",
          },
          {
            type: "inside",
            xAxisIndex: 0,
            filterMode: "none",
          },
          {
            type: "inside",
            yAxisIndex: 0,
            filterMode: "none",
          },
        ],
        series: series,
      };
      // spectroGraph.setOption(option);
      // 使用刚指定的配置项和数据显示图表。
      if (option && typeof option === "object") {
        this.spectroGraph.setOption(option, true);
      }
    },

    // 初始化 弹窗
    init() {
      // get data from Cookies

      const userId = sessionStorage.getItem("userId");
      try {
        const cookiesTolerance = JSON.parse(
          Cookies.get(userId + "diffTolerance")
        );
        if (cookiesTolerance && cookiesTolerance.length > 0) {
          this.diffTolerance = cookiesTolerance;
        }
      } catch (error) { }

      try {
        const cookiesOpenObsIllSelector = JSON.parse(
          Cookies.get(userId + "openObsIllSelector")
        );
        if (cookiesOpenObsIllSelector && cookiesOpenObsIllSelector.length > 0) {
          this.openObsIllSelector = cookiesOpenObsIllSelector;
        }
      } catch (error) { }

      try {
        const cookiesIllObs = JSON.parse(Cookies.get(userId + "illObs"));
        if (cookiesIllObs && cookiesIllObs.length > 0) {
          this.illObs = cookiesIllObs;
        }
      } catch (error) { }

      try {
        const cookiesCurrentIllObsName = Cookies.get(
          userId + "currentIllObsName"
        );
        if (cookiesCurrentIllObsName) {
          this.currentIllObsName = cookiesCurrentIllObsName;
        }
      } catch (error) { }

      try {
        const cookiesShowAbsValues = Cookies.get(userId + "showAbsValues");
        if (cookiesShowAbsValues) {
          this.showAbsValues = cookiesShowAbsValues == "true" ? true : false;
        }
      } catch (error) { }

      try {
        const cookiesShowDeltas = Cookies.get(userId + "showDeltas");
        if (cookiesShowDeltas) {
          this.showDeltas = cookiesShowDeltas == "true" ? true : false;
        }
      } catch (error) { }

      try {
        const cookiesShowColorBias = Cookies.get(userId + "showColorBias");
        if (cookiesShowColorBias) {
          this.showColorBias = cookiesShowColorBias == "true" ? true : false;
        }
      } catch (error) { }

      this.visible = true;
      this.initiated = true;
      this.getIlluminantTexts();
      this.getObserverAngleTexts();
      this.compareColors();
    },
    initCanvas() {
      // Standard values values
      const a = Number(this.currentStandard.a);
      const b = Number(this.currentStandard.b);
      const c = Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));
      const hRad = Math.atan2(b, a);

      let ctx = null;
      if (this.canvas) {
        ctx = this.canvas.getContext("2d");
        ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      } else {
        const canvas = this.$refs.labCanvas;
        this.canvas = canvas;
        ctx = canvas.getContext("2d");
      }

      const halfWidthX = this.labGraphWidth / 2;
      const halfWidthY = this.labGraphHeight / 2;
      ctx.translate(halfWidthX, halfWidthY);

      // write the line

      ctx.rotate(-hRad);
      ctx.beginPath();

      // Set a start-point
      ctx.moveTo(halfWidthX / -2, 0);

      // Set an end-point
      ctx.lineTo(halfWidthX / 2, 0);
      ctx.lineWidth = 2;
      ctx.strokeStyle = "#C0C4CC";
      ctx.stroke();

      // Draw point of arrow
      const triangleWidth = 30;
      const triangleHeight = 15;
      const x = halfWidthX / 2;
      const y = 0;

      ctx.beginPath();
      ctx.moveTo(x - triangleWidth / 2, y - triangleHeight / 2);
      ctx.lineTo(x, y);
      ctx.lineTo(x - triangleWidth / 2, y + triangleHeight / 2);
      ctx.stroke();

      ctx.rotate(hRad);

      // Draw the line of equal dc
      ctx.beginPath();
      const radius = (Math.sqrt(c) * halfWidthX) / this.axisAndIntervalSize[1];
      let [centerX, centerY] = this.getArrowTip(hRad, radius);
      centerX = -centerX;
      centerY = -centerY;
      ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
      ctx.stroke();

      // find position of arrow tip:
      let [tipX, tipY] = this.getArrowTip(hRad, halfWidthX / 2);

      ctx.fillStyle = "#C0C4CC";
      ctx.font = "12px sans-serif";
      ctx.textAlign = "center";

      let [posX0, posY0] = this.adjustPosition(tipX, tipY, 0);
      ctx.fillText(
        this.$t("common.color.colorDifference.strongerColor"),
        posX0,
        posY0
      );
      let [posX2, posY2] = this.adjustPosition(tipX, tipY, 2);
      ctx.fillText(
        this.$t("common.color.colorDifference.weakerColor"),
        posX2,
        posY2
      );

      let [posX1, posY1] = this.adjustPosition(tipX, tipY, 1);
      let [posX3, posY3] = this.adjustPosition(tipX, tipY, 3);
      let [text1, text2] = this.getColorText(hRad);
      ctx.fillText(text1, posX1, posY1);
      ctx.fillText(text2, posX3, posY3);

      ctx.translate(-halfWidthX, -halfWidthY);
    },
    getColorText(hRad) {
      // Make sure angle is positive
      hRad = hRad < 0 ? hRad + Math.PI * 2 : hRad;

      if (hRad < Math.PI / 4 || hRad > (Math.PI * 7) / 4) {
        return [
          this.$t("common.color.colorDifference.blueBias"),
          this.$t("common.color.colorDifference.yellowBias"),
        ];
      }
      if (hRad > Math.PI / 4 && hRad < (Math.PI * 3) / 4) {
        return [
          this.$t("common.color.colorDifference.redBias"),
          this.$t("common.color.colorDifference.greenBias"),
        ];
      }
      if (hRad > (Math.PI * 3) / 4 && hRad < (Math.PI * 5) / 4) {
        return [
          this.$t("common.color.colorDifference.yellowBias"),
          this.$t("common.color.colorDifference.blueBias"),
        ];
      }
      if (hRad > (Math.PI * 5) / 4 && hRad < (Math.PI * 7) / 4) {
        return [
          this.$t("common.color.colorDifference.greenBias"),
          this.$t("common.color.colorDifference.redBias"),
        ];
      }
      return ["", ""];
    },
    getArrowTip(hRad, arrowLength) {
      // Make sure angle is positive
      hRad = hRad < 0 ? hRad + Math.PI * 2 : hRad;

      var tipX = 0;
      var tipY = 0;

      if (hRad > 0 && hRad < Math.PI / 2) {
        tipY = -(Math.sin(hRad) * arrowLength);
        tipX = Math.sqrt(Math.pow(arrowLength, 2) - Math.pow(tipY, 2));
      }
      if (hRad > Math.PI / 2 && hRad < Math.PI) {
        const angle = Math.PI - hRad;
        tipY = -(Math.sin(angle) * arrowLength);
        tipX = -Math.sqrt(Math.pow(arrowLength, 2) - Math.pow(tipY, 2));
      }
      if (hRad > Math.PI && hRad < (Math.PI * 3) / 2) {
        const angle = (Math.PI * 3) / 2 - hRad;
        tipX = -(Math.sin(angle) * arrowLength);
        tipY = Math.sqrt(Math.pow(arrowLength, 2) - Math.pow(tipX, 2));
      }
      if (hRad > (Math.PI * 3) / 2 && hRad < Math.PI * 2) {
        const angle = Math.PI * 2 - hRad;
        tipY = Math.sin(angle) * arrowLength;
        tipX = Math.sqrt(Math.pow(arrowLength, 2) - Math.pow(tipY, 2));
      }
      return [tipX, tipY];
    },
    adjustPosition(x, y, pos) {
      // pos = 想要的位置，顺时针
      let posX = x;
      let posY = y;

      if (pos == 1) {
        posX = -y;
        posY = x;
      }

      if (pos == 2) {
        posX = -x;
        posY = -y;
      }

      if (pos == 3) {
        posX = y;
        posY = -x;
      }

      // 调整位置
      const adjAmount = 30;
      if (posX > 0 && posY > 0) {
        posX += adjAmount;
        posY += adjAmount;
      }
      if (posX < 0 && posY > 0) {
        posX -= adjAmount;
        posY += adjAmount;
      }
      if (posX < 0 && posY < 0) {
        posX -= adjAmount;
        posY -= adjAmount;
      }
      if (posX > 0 && posY < 0) {
        posX += adjAmount;
        posY -= adjAmount;
      }

      return [posX, posY];
    },
    radToDeg(rad) {
      let angle = rad * (180.0 / Math.PI);
      if (angle < 360) {
        angle += 360;
      }
      return angle;
    },
    swapStandard(sampleId) {
      let sampleToBecomeStandard = this.compareData.samples.filter(
        (item) => item.id == sampleId
      )[0];
      sampleToBecomeStandard.id = 0;

      let standardToBecomeSample = this.compareData.standard;
      standardToBecomeSample.id = sampleId;
      this.compareData.samples[Number(sampleId) - 1] = standardToBecomeSample;
      this.compareData.standard = sampleToBecomeStandard;
      this.compareColors();
    },

    zoomIn() {
      this.zoomLevel = this.zoomLevel - this.zoomLevel * 0.2;
      this.refreshGraphs();
    },
    zoomOut() {
      this.zoomLevel = this.zoomLevel + this.zoomLevel * 0.2;
      this.refreshGraphs();
    },
    refreshGraphs() {
      setTimeout(() => {
        this.initLabGraph();
        this.initCanvas();
      }, 0);
    },
    containsDiff(diffFormula) {
      const numOccurances =
        this.diffTolerance.filter((item) => item.diffFormula == diffFormula)
          .length > 0;

      if (numOccurances && numOccurances.length > 0) {
        return true;
      }
      return false;
    },
    exportPdf() {
      console.log("exportPDF");
      this.saveGraphsAsImages();

    },
    exportExcel() {
      var params = {
        standard: this.compareData.standard,
        samples: this.compareData.samples,
        illObs: this.illObs,
        diffTolerance: this.diffTolerance,
        showOptions: [] // 用于传递 WI CIE、Tint、ΔE94、ΔE*ab
      };
      // 根据条件，将对应的选项加入到 showOptions 数组中
      if (this.showWi) {
        params.showOptions.push('WI CIE');  // 'WI'代表白度指数
      }
      if (this.showTint) {
        params.showOptions.push('Tint');  // 'Tint'代表淡色调指数
      }
      if (this.showE94) {
        params.showOptions.push('dE94');  // 'E94'代表CIE94色差
      }
      if (this.showEab) {
        params.showOptions.push('dE*ab');  // 'Eab'代表色差
      }
      if (this.showStatus) {
        params.showOptions.push('Status');  // 'Eab'代表色差
      }
      axiosDownFile(`/api/c/product/exportColorimetricReportExcel`, params);
      return;
    },
    exportQTX() {
      var params = {
        standard: this.compareData.standard,
        samples: this.compareData.samples,
      };
      axiosDownFile(`/api/c/product/exportColorimetricReportQtx`, params);
      return;
    },
    close() {
      colorCompareStore.methods.clearData();
      this.standardLabData = [];
      this.samplesComparisonData = [];
      this.visible = false;
    },

    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    beforeUpload(file) {
      if (!file) {
        this.$message.error("请选择要上传的文件！");
        return false; // 阻止文件上传
      }
      return true;
    },
    // 文件值改变时触发 change事件
    clearFileChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]];
      } else {
        this.fileList = fileList[0];
      }
    },
    // 文件上传时
    uploadQTXFiles(file, fileList) {
      let falg = "reveal";
      this.uploadQtx(file.file, falg);
      this.isAdd = false;
      // 这边是重新上传文件的逻辑，需要将Total清零
      this.total = 0;
    },
    // 文件上传时
    uploadQTXFilesAdd(file, fileList) {
      let falg = "reveal";
      this.uploadQtx(file.file, falg);
      this.isAdd = true;
    },

    uploadQtx(file, falg) {
      // 请求接口
      this.file = file;
      this.formData = new FormData();
      this.formData.append("file", file);
      parsingDataForQtx(this.formData).then((res) => {
        if (res) {
          console.log(res, "RES");
          if (
            (res.samples && res.samples.length > 0) ||
            (res.standard && this.isAdd)
          ) {
            // fix data
            res.samples.forEach((item, index) => {
              if (
                !item.name ||
                (item.name == "" && item.colorSampleName != "")
              ) {
                item.name = item.colorSampleName;
              }
              item.id = index + 1;
            });
            this.previousFile = file;
            this.uploadingQTXData = [res.standard];
            this.uploadingQTXData = this.uploadingQTXData.concat(res.samples);
            this.importSamplesVisible = true;
            // 默认全选
            this.checkedFiles = Array.from(
              { length: this.uploadingQTXData.length },
              (value, index) => index
            );
          } else {
            // 错误信息
            this.$message({
              message: this.$t("search.compareColors.noOrIncompleteData"),
              type: "error",
            });
            this.fileList = [this.previousFile];
          }
        } else {
          // 错误信息
          this.$message({
            message: this.$t("search.compareColors.noOrIncompleteData"),
            type: "error",
          });
          this.fileList = [this.previousFile];
        }
      });
    },
    confirmAddSamples() {
      if (!(this.checkedFiles && this.checkedFiles.length > 0)) {
        // Please select files
        this.$message.error(
          this.$t("search.compareColors.addSamples.selectFiles")
        );
        return;
      }
      if (!this.isAdd && this.checkedFiles.length < 2) {
        // There is no standard yet, user must select at least 2 data points to compare
        this.$message.error(
          this.$t("search.compareColors.addSamples.selectAtLeastTwoFiles")
        );
        return;
      }

      let dataToAdd = [];
      this.checkedFiles.forEach((item) => {
        dataToAdd.push(this.uploadingQTXData[item]);
      });

      dataToAdd.forEach((item, index) => {
        item.id = index;
        item.no = index;
      });

      let standard;
      let samples;

      if (this.isAdd) {
        standard = this.compareData.standard;
        samples = this.compareData.samples.concat(dataToAdd);
        samples.forEach((item, index) => {
          item.id = index + 1;
          item.no = index + 1;
        });
      } else {
        standard = dataToAdd[0];
        samples = dataToAdd;
        samples.shift();
      }

      colorCompareStore.methods.setData(standard, samples, false);
      this.init();
      this.importSamplesVisible = false;
      console.log(this.checkedFiles.length, "COMPAREDATA");
      if (this.total != 0) {
        this.total += this.checkedFiles.length
      } else {
        this.total = this.checkedFiles.length
      }

    },

    handleCheckAllChange(val) {
      this.checkedFiles = val
        ? Array.from(
          { length: this.uploadingQTXData.length },
          (value, index) => index
        )
        : [];
      this.isIndeterminate = false;
    },
    handleCheckedFilesChange(value) {
      let checkedCount = value.length;
      this.checkAllFiles = checkedCount === this.uploadingQTXData.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.uploadingQTXData.length;
    },
    getText(text) {
      this.editingSampleName = text.target.value;
    },
    // 校验函数
    validateSampleName() {
      const newName = this.editingSampleName;
      if (!newName || newName.trim() === '') {
        this.$message.error(this.$t("tips.message.InputValidation"));
        return false;
      }
      if (newName.length > 100) {
        this.$message.error(this.$t("tips.message.InputValidation"));
        return false;
      }
      return true;
    },
    saveSampleName(sampleId) {
      const newName = this.editingSampleName;
      if (this.validateSampleName()) {
        if (sampleId == 0) {
          const samples = this.compareData.samples;
          var standard = this.compareData.standard;
          standard.name = newName;
          colorCompareStore.methods.setData(standard, samples, false);
        } else {
          let samples = this.compareData.samples;
          const standard = this.compareData.standard;

          samples[sampleId - 1].name = newName;
          colorCompareStore.methods.setData(standard, samples, false);
        }
        this.compareColors();
        this.editingSample = null;
      }

    },
    removeSample(sampleId) {
      let samples = this.compareData.samples;
      let standard;
      if (!(samples && samples.length > 1)) {
        // 只有一个试样的时候不能再移除！
        this.$message.error(this.$t("You cannot remove the last sample!"));
        return;
      }
      if (Number(sampleId) == 0) {
        // 移除标样
        standard = samples[0];
        samples.shift();

        samples.forEach((item, index) => {
          item.id = index + 1;
        });
      } else {
        samples = samples.filter((obj) => Number(obj.id) != Number(sampleId));
        samples.forEach((item, index) => {
          item.id = index + 1;
        });
        standard = this.compareData.standard;
      }

      colorCompareStore.methods.setData(standard, samples, false);
      this.compareColors();
      this.total -= 1;
    },

    startDrag(evt, dragItemId) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("dragItemId", dragItemId);
    },
    onDrop(evt, dropItemId) {
      const dragItemId = evt.dataTransfer.getData("dragItemId");
      let allSampl = [this.standard, ...this.compareData.samples];

      let dragItem =
        Number(dragItemId) == 0
          ? this.standard
          : this.compareData.samples.find((item) => item.id == dragItemId);

      allSampl.splice(dragItemId, 1);
      allSampl.splice(dropItemId, 0, dragItem);

      // // reconvert to sample/standard
      allSampl.forEach((item, index) => {
        item.id = index;
      });

      let samples = allSampl;
      let standard = samples.shift();

      // reorder list
      samples.sort(
        (firstItem, secondItem) => Number(firstItem.id) - Number(secondItem.id)
      );

      colorCompareStore.methods.setData(standard, samples, false);
      this.compareColors();

      evt.target.classList.remove("drag-over");
    },
    dragEnter(e) {
      e.preventDefault();
      e.target.classList.add("drag-over");
    },
    dragOver(e) {
      e.preventDefault();
      // e.target.classList.remove("drag-over");
    },
    dragLeave(e) {
      e.target.classList.remove("drag-over");
    },

    tableRowClassName({ row, rowIndex }) {
      if (rowIndex < this.standardLabData.length && this.showAbsValues) {
        return "standard-row";
      } else {
        return undefined;
      }
    },

    isPassTolerance(deFormula, tolerance, row) {
      console.log(row);
      console.log(this.diffTolerance);
      this.diffTolerance.filter((obj) => {
        if (obj.diffFormula == deFormula) {
          console.log(tolerance, obj.tolerance);
          console.log(
            tolerance < obj.tolerance ? "de-pass" : "de-fail",
            "ispass?"
          );
          return tolerance < obj.tolerance ? "de-pass" : "de-fail";
        }
      });
    },
    // 获取观察者数据
    getObserverAngleTexts() {
      getObserverAngleTextsApi().then((records) => {
        if (records.length > 0) {
          this.observerAngleList = records;
          // if (records.includes("10 deg")) {
          //   this.selectedValue = "10 deg";
          // } else {
          //   this.selectedValue = records[0];
          // }
          // this.observerAngleChange();
        } else {
          this.observerAngleList = [];
        }
      });
    },
    getIlluminantTexts() {
      getIlluminantTextsApi().then((records) => {
        if (records.length > 0) {
          this.illuminantList = records;
          // if (records.includes("D65")) {
          //   this.selectedValue = "D65";
          // } else {
          //   this.selectedValue = records[0];
          // }
          // this.illuminantChange();
        } else {
          this.illuminantList = [];
        }
      });
    },
  },
};
</script>
<style lang="scss">
.standard-row {
  background-color: #fafafa !important;
}
</style>
<style lang="scss" scoped>
.de-fail {
  color: red;
  font-weight: 500;
}

.de-pass {
  color: green;
  font-weight: 500;
}

.de-other {
  color: grey;
}

div.drag-over {
  border-top: solid 2px grey;
}

.graph-pane {
  width: 500px;
  height: 400px;
  margin-left: 20px;
}

.tab-header {
  margin-left: 10px;
  opacity: 0.8;
  height: 30px;
  padding-top: 12px;
  margin-bottom: -2px;
  margin-top: 84px;
}

.settings-visible {
  // border: 1px solid #c0c0c0;
  background-color: #fcfcfc;
}

.qtx-title {
  display: inline-block;
  max-width: 90%;
  padding-right: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tab-label {
  display: inline;
  margin-right: 20px;
  margin-top: 80px;
  font-size: 16px;
  padding: 4px;
}

.tab-label:hover {
  background: #fcfcfc;
}

.selected-tab {
  opacity: 1;
  color: black;
  font-weight: bold;
}

.tab-content {}

.echars {
  width: 100%;
  height: 400px;
  display: flex;
}

.echarts-chart_4 {
  width: 85%;
  height: 100%;
}

.echarts-chart_5 {
  width: 15%;
  height: 100%;
}

.echarts-chart_spectro {
  width: 500px;
  height: 400px;
}

.qtx-file-title {
  display: inline-block;
  margin-top: -50px;
  color: grey;
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 1.2;
  padding-left: 10px;
  max-width: 400px;
  // margin-bottom: 20px;
}

.comparison-parameters {
  padding: 8px;
  margin-bottom: 4px;
}

.first-row {
  height: 70px;
  display: block;
  margin-bottom: 25px;
  margin-top: -25px;
  padding-top: 38px;
  color: grey;
  font-size: 14px;
}

.comparison-zone {
  background-color: #969696;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
}

.comparison-header {
  color: #fff;
  font-weight: bold;
  padding: 15px 10px 15px 10px;
  display: flex;
  justify-content: space-evenly;
}

.sample-name {
  margin-left: 5px;
  padding-right: 15px;
  border: 1px solid #fff;
  user-select: text;
}

.sample-text {
  width: calc(100% - 80px);
  margin-top: 5px;
  line-height: 0.8;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
}

.move {
  cursor: grab;
}

.sample-name:hover {
  border: 1px solid black;
}

.hidden-icon {
  visibility: hidden;
}

.sample-name:hover {
  border: 1px solid black;
}

.sample-name:hover .hidden-icon {
  visibility: visible;
}

.table-illobs:hover .hidden-icon {
  visibility: visible;
}

.scroll-samples::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll-samples {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  max-height: 225px;
  overflow-y: scroll;
  display: block;
}

.comparison-last-row {
  padding-bottom: 10px;
}

.color-comparison-square {
  display: block;
  background-color: black;
  height: 50px;
  width: 70px;
}

.comparison-params-dialog {
  display: flex;
  justify-content: space-between;
  font-weight: bold;

  .select {
    margin-left: 5px;
  }
}

.clickable {
  cursor: pointer;
  opacity: 0.8;
}

.clickable:hover {
  opacity: 1;
}

.color-bias {
  font-size: 11.5px;
  display: inline;
  border: 1px solid #c0c0c0;
  padding: 1px;
  margin-right: 2px;
  margin-left: 2px;
  border-radius: 3px;
  line-height: 5px !important;
}

.color-bias:hover {
  border: 1px solid #7a7a7a;
  cursor: default;
}

.id-dot {
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  margin-right: 3px;
}

.list-dot {
  margin-right: 5px;
  margin-bottom: 1px;
  margin-left: 8px;
  height: 10px;
  width: 10px;
  border-radius: 5px;
}

.zoom-menu {
  position: absolute;
  right: 15px;
}

.label {
  display: inline;
  font-size: small;
  margin-right: 5px;
}

.main-content {
  background-color: white;
  padding: 48px;
  // margin-left: calc((100% - 1150px) / 2);
  min-width: 1200px;
}

.select-files-container {
  max-height: 350px;
  width: 360px;
  overflow-y: auto;
  margin-bottom: 40px;

  .select-files-checkbox {
    width: 340px;
    height: 30px;
    padding: 5px;
    position: relative;

    .select-files-text {
      width: 280px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .select-files-color {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 2px;
      right: 3px;
    }
  }
}

::v-deep .el-checkbox__inner {
  margin-bottom: 5px !important;
}

.label-total {
  font-size: 14px;
  margin-top: -25px;
  margin-left: 320px;
}
</style>